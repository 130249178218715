/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** AddtoCart Popup style */

.AddToCart-Popup {
    .Popup {
        &-Content {
            @include tablet {
                padding: 30px 43px 30px !important;
            }

            @include mobile {
                padding: 23px 40px 30px;
            }
        }

        &-Header {
            padding-block-start: 20px;

            @include mobile {
                padding-block-start: 0;
            }

            h3 {
                @include mobile {
                    display: none;
                }
            }
        }

        &.RightSidebar {
            @include mobile {
                justify-content: center;
                align-items: flex-start;
            }
        }
    }

    &.Popup {
        &.RightSidebar {
            @include mobile {
                justify-content: center;
                align-items: flex-start;
            }
        }
    }

    .Popup-CloseBtn {
        inset-inline-start: auto !important;
        inset-inline-end: 0 !important;

        svg {
            stroke: $default-secondary-dark-color;
        }
    }

    .AddToCartPopup {
        &-Content {
            height: 100%;
            padding-block-start: 25px;
            
            @include ultra-narrow-desktop {
                padding-block-start: 0;
            }
            
            @include tablet {
                padding-block-start: 0;
            }

            @include mobile {
                height: 90%;
                padding-block-start: 0;
            }

            h2 {
                display: block;
                max-width: 200px;

                @include narrow-desktop {
                    margin-block-start: 0;
                }

                @include ultra-narrow-desktop {
                    margin-block-start: 0;
                    max-width: 230px;
                }

                @include tablet {
                    margin-block-start: 0;
                    max-width: 230px;
                }
                
                @include mobile {
                    font-size: 2.7rem;
                    margin-block-start: 0;
                    margin-block-end: 0;
                }
            }
        }

        &-Product {
            padding-block-start: 30px;

            @include tablet {
                padding-block-start: 10px;
            }

            .ProductCard-Picture {
                max-width: 170px;
                border: none;
                padding-block-end: 170px;
                display: block;
                margin-block-end: 20px;

                @include ultra-narrow-desktop {
                    max-width: 150px;
                    padding-block-end: 150px;
                }

                @include tablet {
                    max-width: 140px;
                    padding-block-end: 140px;
                    margin-block-end: 15px;
                }

                img {
                    width: 100%;
                    height: 100%;
                }

                &::after {
                    content: " ";
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: #ebeff2;
                    position: absolute;
                    inset-block-start: 0;
                    inset-inline-start: 0;
                    z-index: 10;
                    opacity: .7;
                    mix-blend-mode: multiply;
                }
            }

            .CartItem {
                &-Brand {
                    font-weight: 700;
                    color: $black;
                    font-family: $font-muli;
                    text-transform: none;
                    font-size: 1.4rem;
                    line-height: 1;
                    margin-block-end: 5px;
                }

                &-Heading {
                    font-size: 2.1rem;
                    line-height: 1.2;
                    font-family: $font-muli;
                    text-transform: uppercase;
                    font-weight: 700;
                    margin-block-end: 10px;

                    @include tablet {
                        font-size: 2rem;
                    }

                    @include mobile {
                        font-size: 1.8rem;
                        margin-block-end: 5px;
                    }
                }

                &-Quantity {
                    font-size: 1.4rem;
                    line-height: 1.2;
                    font-weight: 300;
                    margin-block-end: 8px;
                }

                &-Price {
                    font-family: $font-muli;
                    font-weight: 700;
                    text-align: start;
                    font-size: 2.1rem;
                    line-height: 1.2;

                    @include narrow-desktop {
                        font-size: 1.8rem;
                    }

                    @include tablet {
                        font-size: 1.7rem;
                        margin-block-end: 0;
                    }

                    @include mobile {
                        font-size: 1.6rem;
                    }
                }
            }
        }

        &-Actions {
            background-color: #fff;
            padding-block-start: 40px;

            @include mobile {
                position: relative;
                inset-block-end: 0;
                width: 100%;
                padding-block-start: 30px;
            }

            .Button {
                margin-block-end: 17px;

                @include mobile {
                    max-width: 100%;
                }
            }
        }
    }
}
