/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */
/* stylelint-disable declaration-no-important */

:root {
    --cart-page-divider-background: var(--secondary-base-color);
    --cart-page-promo-background: #{$default-primary-base-color};
    --cart-page-table-head-background: var(--secondary-base-color);
    --desktop-loader-offset: calc(0px - var(--header-total-height) - var(--breadcrumbs-height) - 32px);
}

.CartPage {
    margin-block-end: var(--footer-total-height);

    @include mobile {
        --footer-totals-height: 0;

        margin-block-end: 0;
        padding-block-end: var(--footer-totals-height);
        padding-inline: 16px;
        overflow-x: hidden;
    }

    &-Top {
        text-align: center;
        margin-block-end: 60px;

        @include ultra-narrow-desktop {
            max-width: 100%;
        }

        @include tablet {
            max-width: 100%;
        }

        @include mobile {
            width: 100%;
            margin-block-end: 30px;
        }

        h1, 
        .CartPage-Heading {
            text-transform: uppercase;
            margin-block-start: 0;
        }
    }

    &-Items {
        padding: 20px 0 45px;

        @include mobile {
            border: none;
            padding: 0;
        }

        div:last-child {
            .CartItem {
                @include mobile {
                    border-bottom: none;
                }
            }
        }
    }

    &-Wrapper {
        @include desktop {
            display: block;
            max-width: 1170px;
            grid-template-columns: auto 350px;
            grid-column-gap: 80px;
            margin-block-end: 30px;
        }

        @include ultra-narrow-desktop {
            max-width: 95vw;
        }

        @include tablet {
            max-width: 95vw;
            grid-column-gap: 12px;
        }

        @include mobile {
            padding: 0;
        }
    }

    &-InitialLoaderContainer {
        inset-inline-start: 0;
        inset-block-start: 0;
        z-index: 10;
        position: fixed;

        @include desktop {
            max-width: 100vw;
            max-height: 100vh;
            width: 100vw;
            height: 100vh;
        }

        .Loader {
            margin: none;

            &-Scale {
                @include mobile {
                    position: fixed;
                    inset-block-start: auto;
                    inset-block-end: 50%;

                    &:dir(rtl) {
                        transform: translate(50%, 50%);
                    }

                    &:dir(ltr) {
                        transform: translate(-50%, 50%);
                    }
                }
            }
        }
    }

    &-Empty {
        @include mobile {
            padding: 14px;
            border-block-end: 1px solid var(--expandable-content-divider-color);
            text-align: center;
        }
    }

    &-Summary {
        background: $default-neutral-light-color;

        @include mobile {
            inset-inline-start: 0;
            width: 100%;
        }
    }

    &-CheckoutButtons {
        padding: 12px 0;

        @include mobile {
            padding: 14px;
        }
    }

    &-OutOfStockProductsWarning {
        padding: 10px;
        display: flex;
        justify-content: center;
        background-color: var(--primary-error-color, red);
        font-size: 14px;
        font-weight: 600;
        color: var(--color-white);
        inset-inline-start: -16px;
        width: calc(100% + 32px);
    }

    &-CheckoutButton {
        width: 100%;
        text-align: center;

        @include desktop {
            margin: 10px 0;
        }
    }

    &-Promo {
        max-width: 1170px;
        min-height: 42px;
        margin: 0 auto;
        order: 1;
        background: var(--cart-page-promo-background);

        @include desktop {
            margin-block-start: 24px;
        }

        @include ultra-narrow-desktop {
            max-width: 95vw;
        }

        @include tablet {
            max-width: 95vw;
        }

        @include mobile {
            padding: 0;
            min-height: 0;

            .box-vantaggi-cart.mb-3 {
                margin-block-end: 22px !important;
            }
        }

        .CmsBlock-Wrapper {
            h3 {
                @include mobile {
                    font-size: 12px !important;
                    font-weight: 600 !important;
                    line-height: 1.4 !important;
                    letter-spacing: normal !important;
                    margin: 0;
                    text-align: center !important;
                    height: 100%;
                }

                img {
                    height: 30px;
                    width: auto;
                    vertical-align: middle;
                    margin-inline-end: 5px;
                    margin-block-start: -3px;

                    @include mobile {
                        margin-inline-end: auto;
                        margin: 0 auto;
                        display: block;
                        margin-block-end: 6px;
                        width: auto !important;
                        max-height: 25px;
                    }
                }
            }

            .pagebuilder-column {
                &:first-child,
                &:last-child {
                    padding-inline: 16px;
                }

                &:nth-child(2) {
                    @include desktop-wide-1600 {
                        min-width: 250px;
                    }

                    @include mobile {
                        min-width: 90px;
                    }
                }

                h3 {
                    @include ultra-narrow-desktop {
                        font-size: 1.6rem;
                    }

                    @include tablet {
                        font-size: 1.6rem;
                    }
                }
            }

            div:has(.box-vantaggi-cart) {
                display: flex;
                justify-content: center;
                width: 100%;
            }

            .box-vantaggi-cart {
                max-width: 900px;
                width: 100%;

                @include ultra-narrow-desktop {
                    max-width: 780px;
                }

                @include tablet {
                    max-width: 750px;
                }

                @include mobile {
                    &.mb-5 {
                        margin-block-end: 13px !important;
                    }
                }
            }

            .banner-sped-eco {
                width: 100%;
                padding: 9px;
                font-size: 1.4rem;
                justify-content: start !important; /* stylelint-disable-line */

                @include ultra-narrow-desktop {
                    max-width: 95vw;
                    margin: 0 auto;
                    min-height: 48px;
                    justify-content: center !important;
                }

                @include tablet {
                    max-width: 95vw;
                    margin: 0 auto;
                    min-height: 48px;
                    justify-content: center !important;
                }

                @include mobile {
                    padding: 10px 15px;
                    font-size: 11px; 
                    min-height: 82px;
                    justify-content: flex-start !important;
                    
                    &.mb-3 {
                        margin-block-end: 0 !important;
                    }
                }

                p {
                    margin-block-end: 0;
                    text-align: start !important;
                    font-size: 1.4rem;
                    display: flex;
                    align-items: center;
                    color: $white;

                    &::before {
                        position: relative;
                        content: var(--logo-cart);
                        margin-inline-end: 15px;
                    }

                    @include desktop-wide-1600 {
                        margin-inline-end: 75px;
                    }

                    @include ultra-narrow-desktop {
                        line-height: 1;
                        padding-inline-start: 10px;
                        text-align: start !important;
                    }

                    @include tablet {
                        line-height: 1;
                        padding-inline-start: 10px;
                        text-align: start !important;

                        strong {
                            margin-inline-start: 0;
                        }
                    }

                    @include mobile {
                        margin-inline-end: 0;
                        flex-direction: column;

                        span {
                            font-size: 12px !important;
                        }

                        strong {
                            display: block;
                        }
                    }
                }

                a {
                    text-decoration: underline;
                    font-size: 12px;
                    font-weight: 600;
                }

                img {
                    vertical-align: middle;
                    margin-inline-end: 5px;
                }
            }
        }

        &Block {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid var(--cart-page-divider-background);
            padding: 24px 12px;
            border-radius: var(--input-border-radius);

            @include mobile {
                margin-block: 12px;
                padding: 14px;
            }
        }

        &Image {
            width: 46px;
            margin-inline-end: 6px;

            @include mobile {
                width: 36px;
                margin-inline-end: 7px;
            }
        }

        strong {
            margin: 0 5px;

            @include ultra-narrow-desktop {
                margin-inline-start: 0;
            }

            @include mobile {
                margin-inline: 0;
                margin-block-end: 5px;
            }
        }

        .ProductActions-Info {
            text-decoration: underline;
            font-size: 1.2rem;
            font-weight: 600;
            position: absolute;
            margin-inline-start: 43px;
            inset-inline-end: 20px;
            inset-block-end: 0;
            padding-block: 11px;
            cursor: pointer; 
            display: flex;
            align-items: center;
            color: $white;

            @include ultra-narrow-desktop {
                inset-inline-end: 40px;
            }

            @include tablet {
                inset-inline-end: 40px;
            }

            @include mobile {
                font-size: 12px;
                inset-block-end: 0;
                inset-inline-end: 0;
                margin-inline-start: 0;
                position: relative;
                width: 100%;
                justify-content: center;
                padding-block-start: 0;
                padding-block-end: 15px;
                color: $white;
            }

            svg {
                vertical-align: bottom;
                margin-inline-end: 5px;

                path, circle, line {
                    stroke: $white;
                }
            }

            span {
                color: $white;
            }
        }
    }

    &-ExpandableContentButton {
        .ChevronIcon {
            @include desktop {
                display: none;
            }
        }
    }

    &-Total {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        align-items: center;
        font-weight: bold;
        padding: 12px 0;
        min-height: 48px;

        @include mobile {
            min-height: 0;
            padding-block-start: 14px;
            padding-block-end: 0;
            padding-inline: 14px;
        }

        dd {
            text-align: end;

            span {
                display: block;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }

    &-TableHead {
        display: grid;
        grid-template-columns: 3fr 2fr 1fr;
        background-color: var(--cart-page-table-head-background);
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 600;
        padding: 14px 16px;
        margin-block-end: 0;
        border-radius: var(--input-border-radius);

        @include mobile {
            display: none;
        }

        span {
            text-align: start;

            &:last-of-type {
                text-align: end;
            }
        }
    }

    & &-Discount {// hack to obtain required specificity
        border-block-start: 0;
        
        @include desktop {
            margin-block-start: 0;
            margin-block-end: 45px;
        }

        @include ultra-narrow-desktop {
            border-block-end: 0;
            padding-inline: 0;
            margin-block-end: 25px;
        }

        @include tablet {
            border-block-end: 0;
            padding-inline: 0;
            margin-block-end: 25px;
        }

        @include mobile {
            border-block-start: none;
            border-block-end: 0;
            padding-inline: 0;
        }

        .ExpandableContent {
            &-Heading {
                font-family: $font-muli;
                font-size: 1.6rem;
                line-height: 1.25;
                font-weight: bold;
                letter-spacing: 0.029rem;
                color: $black;
                margin-block-end: 16px;

                @include narrow-desktop {
                    font-size: 1.6rem;
                }

                @include tablet {
                    font-size: 1.6rem;
                }

                @include mobile {
                    font-size: 1.6rem;
                    font-weight: 700;
                    letter-spacing: normal;
                    margin-block-end: 0;
                }
            }

            &-Button {
                @include mobile {
                    padding-block-end: 0;
                }
            }
        }

        .CartCoupon {
            .Form {
                width: 100%;
                display: inline-flex;
                flex-direction: column;
                margin: 0;

                @include mobile {
                    margin-block-start: 5px;
                }
            }

            &.NoCoupon {
                .Form {
                    flex-direction: row;
                    border: 1px solid $default-secondary-medium-color;
                    max-height: 45px;
                }

                .CartCoupon-Button {
                    padding-inline-end: 20px;

                    @include mobile {
                        padding-inline-end: 0;
                    }
                }
            }

            &-MessageText {
                border: 1px solid $default-secondary-medium-color;
                padding: 10px;
                margin: 0;
                text-transform: none;
            }

            &-Input {
                flex: 1;

                @include ultra-narrow-desktop {
                    margin-block-end: 10px;
                }

                @include tablet {
                    margin-block-end: 10px;
                }

                @include mobile {
                    margin-inline-end: 0;
                }

                input {
                    width: 100%;
                    height: 43px;
                    border: 0 !important;
                    color: $black !important;
                    font-size: 1.4rem;
                }
            }

            &-Button {
                height: 45px;
                border: none;
                border-radius: 0px;
                background-color: transparent;
                color: $black;
                text-decoration: underline; 
                font-family: $font-muli;
                font-size: 1.4rem;
                line-height: 1.4rem;
                font-weight: normal;
                letter-spacing: 0.025rem;
                text-transform: initial;
                cursor: pointer;
                padding: 0;
                display: block;
                text-align: start;

                @include ultra-narrow-desktop {
                    height: 50px;
                }

                @include tablet {
                    height: 50px;
                }

                @include mobile {
                    margin-block-start: 0;
                    max-width: 110px;
                }
            }
        }

        svg {
            @include mobile {
                display: none;
            }
        }
    }

    &-Floating {
        @include desktop {
            display: inline-block;
            flex-direction: column;
            margin-block-start: 0;
            margin-inline-start: 5%;
            position: sticky;
            inset-block-start: 0;
            inset-inline-end: 0;
            width: 31.6%;
            vertical-align: top;
        }

        @include desktop-wide-1600 {
            margin-inline-start: 4%;
            width: 31%;
        }

        @include tablet {
            margin-inline-start: 4%;
            width: 31%;
            margin-block-start: 0;
        }

        @include mobile {
            margin-block-start: 21px;
            width: 100%;
        }
    }

    &-Static {
        @include desktop {
            width: 62.5%;
            display: inline-block;
            vertical-align: top;
        }

        @include desktop-wide-1600 {
            width: 65%;
        }

        @include mobile {
            width: 100%;
        }

        .h3 {
            margin-block-start: 0;
            margin-block-end: 10px;
        }
    }

    &-Heading {
        margin-block: 24px;

        @include mobile {
            display: block;
            margin-block: 14px;
        }
    }

    &-ExpandableContentContent {
        margin-block-start: 0;

        @include mobile {
            opacity: 1;
            max-height: 100%;
        }
    }

    .ProductLinks-Wrapper {
        padding-inline: 0;

        @include desktop {
            padding-inline: 32px;
        }
    }

    .ProductLinks-List {
        grid-column-gap: 16px;
        z-index: 10;

        .ProductCard-ProductActions {
            display: flex;
        }

        .AddToCart {
            width: 100%;
            margin-inline-end: 0;
        }
    }

    .CheckoutOrderSummary {
        .Loader {
            display: none;
        }
    }

    .CartItem {
        &-Brand {
            @include desktop-wide-1440 {
                width: 100%;
                font-size: 1.6rem;
                line-height: 1.2;
            }
        }

        &-Heading {
            @include desktop-wide-1440 {
                font-size: 1.8rem;
                line-height: 1.2;
            }
        }
    }

    .box-vantaggi-cart {
        .pagebuilder-column {
            h3 {
                line-height: 40px;
                border-right: 1px solid #6D6D6D;
                font-family: $font-muli;
                font-size: 1.4rem;
                font-weight: normal;
                letter-spacing: 0.017rem;
                color: $default-neutral-base-color;

                @include mobile {
                    border-right: none;
                }
            }
        }

        .pagebuilder-column:nth-child(2) h3 {
            @include mobile {
                border-right: 1px solid #6D6D6D;
                border-left: 1px solid #6D6D6D;
            }
        }

        .pagebuilder-column:last-of-type h3 {
            border-right: none;
        }
    }
}
