/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
    override Sidea
 */

:root {
    --cart-item-subheading-color: #888;
}

.CartItem {
    --header-color: var(--color-black);

    &-QuantityStocks {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-around;
        width: 46%;
    }

    &-OutOfStock {
        font-weight: bold;
        margin-block-end: 5px;
    }

    &-Link {
        color: inherit;
        font-weight: 400;
        align-self: start;

        &:hover {
            text-decoration: none;

            .CartItem-ItemName {
                color: $default-primary-base-color;
            }
        }
    }

    &_isCartOverlay:last-of-type {
        border-block-end: none;
    }

    &-Content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 0;
    }

    &-Wrapper {
        display: grid;
        grid-gap: 12px;
        padding: 12px;
        padding-block-start: 45px;
        background: var(--color-white);
        align-items: center;
        grid-template-columns: minmax(100px, 3fr) 2fr 1fr;

        &_isMobileLayout {
            grid-template-columns: 64px auto;
            grid-gap: 14px;
            padding: 14px 0;
            min-height: 130px;

            @include mobile {
                grid-template-columns: 75px auto;
                padding: 20px 0 23px;
                border-bottom: 1px solid #E2E2E2;
            }
        }

        &_isSummary {
            & .CartItem-ProductInfo {
                gap: 5px
            }
        }

        &_isCart {
            padding-inline: 0;
            grid-template-columns: minmax(100px, 6fr) 5fr;
            align-items: end;
            border-block-end: 1px solid #E2E2E2;
            padding-block-start: 25px;
            padding-block-end: 25px;

            @include ultra-narrow-desktop {
                grid-template-columns: minmax(100px, 4fr) 4fr;
                padding-block-start: 20px;
                padding-block-end: 20px;
            }

            @include tablet {
                grid-template-columns: minmax(100px, 4fr) 4fr;
                padding-block-start: 20px;
                padding-block-end: 20px;
            }

            .CartItem {
                &-InfoWrapper {
                    width: auto;
                    display: flex;
                    flex-direction: column;
                    padding: 0 30px;
                    padding-inline-end: 0;

                    @include ultra-narrow-desktop {
                        padding: 0px 20px;
                    }

                    @include tablet {
                        padding: 0px 20px;
                    }
                }

                &-Title {
                    @include desktop {
                        padding-inline-start: 0;
                        margin-block-end: 5px;
                    }

                    .CartItem-Heading {
                        font-size: 2rem;
                        font-weight: 300;
                        white-space: unset;
                        overflow: visible;

                        @include desktop-wide-1440 {
                            font-size: 1.8rem;
                        }

                        @include ultra-narrow-desktop {
                            font-size: 1.6rem;
                        }

                        @include tablet {
                            font-size: 1.6rem;
                        }
                    }
                }

                &-Brand {
                    line-height: 1.4;
                }

                &-Picture {
                    padding: 4px;
                    text-align: center;
    
                    @include desktop {
                        width: 110px;
                        max-width: 110px;
                        height: 110px;
                        max-height: 110px;
                        padding: 0;
                    }

                    @include ultra-narrow-desktop {
                        width: 100px;
                        min-width: 95px;
                        max-height: 100px;
                    }

                    @include tablet {
                        width: 100px;
                        min-width: 95px;
                        max-height: 100px;
                    }

                    @include mobile {
                        width: 75px;
                        height: 75px;
                    }

                    img {
                        @include desktop {
                            position: relative;
                            width: auto;
                            height: 100%;
                        }
                    }

                    &::after {
                        content: " ";
                        display: block;
                        width: 100%;
                        height: 100%;
                        background-color: #ebeff2;
                        position: absolute;
                        inset-block-start: 0;
                        inset-inline-start: 0;
                        z-index: 10;
                        opacity: .7;
                        mix-blend-mode: multiply;
                    }
                }

                &-Price {
                    text-align: start;
                    font-size: 1.4rem;

                    @include ultra-narrow-desktop {
                        font-size: 1.6rem;
                    }
            
                    @include tablet {
                        font-size: 1.6rem;
                    }
                }

                &-ProductActions {
                    align-items: flex-end;
                    grid-column-gap: 40px;
                    column-gap: 40px;
                    justify-content: space-between;

                    @include desktop-wide-1600 {
                        justify-content: flex-end;
                        align-items: center;
                    }

                    @include ultra-narrow-desktop {
                        justify-content: flex-end;
                        column-gap: 20px;
                    }
                    
                    @include tablet {
                        justify-content: flex-end;
                        column-gap: 20px;
                    }
                }

                &-QuantityStocks {
                    width: 53%;
                    gap: 10px;

                    span {
                        font-size: 12px;
                    }

                    @include ultra-narrow-desktop {
                        width: 100%;
                        gap: 8px;
                        justify-content: flex-end;
                    }

                    @include tablet {
                        width: 100%;
                        gap: 8px;
                        justify-content: flex-end;
                    }

                    .CartItem-Qty {
                        width: 120px;

                        @include ultra-narrow-desktop {
                            width: 100%;
                        }

                        @include tablet {
                            width: 100%;
                        }

                        input {
                            width: 100%;
                            max-width: 70px;

                            @include ultra-narrow-desktop {
                                max-width: 50px;
                            }
    
                            @include tablet {
                                max-width: 50px;
                            }
                        }
                    }
                }
            }
        }

        &_isProductOutOfStock {
            .CartItem-ProductActions_isMobileLayout {
                align-self: flex-end;
            }
        }
    }

    &-ProductInfo {
        display: flex;
        flex: 1;

        &_isMobileLayout {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            gap: 5px;

            @include mobile {
                gap: 0;
            }

            .CartItem-Variants {
                width: 60%;
                display: flex;

                @include mobile {
                    width: 100%;
                    flex-direction: column;
                    gap: 7px;
                    margin-block-end: 10px;
                }
            }
        }

        .Variant {
            font-family: $font-base;
            font-size: 10px;
            line-height: 1.3;
            letter-spacing: 0;
            color: $black;
            margin-inline-end: 6px;

            @include mobile {
                font-size: 1.4rem;
                margin-inline-end: 0;
            }

            span {
                font-weight: bold;
            }

            &.Color {
                margin-block-end: 2px;
            }
        }
    }

    &-Brand {
        font-family: $font-muli;
        font-size: 1.4rem;
        line-height: 1.5;
        font-weight: 600;

        &_isMobileLayout {
            font-size: 1.4rem;
            line-height: 1.5;
            margin-block-end: 1px;
            width: 90%;

            @include mobile {
                width: 100%;
                font-size: 10px;
                line-height: 1.1;
            }
        }
    }

    &-ItemName {
        font-family: $font-base;
        font-size: 1.6rem;
        line-height: 1.28;
        letter-spacing: 0;
        color: $black;
        margin: 0;

        @include mobile {
            font-size: 1.5rem;
        }
    }

    &-Options {
        font-family: $font-base;
        font-size: 1.4rem;
        line-height: 2.4rem;
        letter-spacing: 0;
        color: $default-neutral-base-color;
    }

    &-Option {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 16px;
        display: flex;
        gap: 4px;

        &_isBundle {
            flex-direction: column;
            gap: 0;
        }

        span,
        div {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &-ItemLinks {
        &Wrapper {
            display: flex;
            flex-direction: column;
            color: var(--secondary-dark-color);
            font-size: 12px;
        }
    }

    &-Title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        overflow: hidden;

        &_isMobileLayout {
            width: 90%;

            @include mobile {
                width: 100%;
            }
        }

        @include mobile {
            margin-block-end: 10px;
            font-size: 1.6rem;
        }
    }

    &-Heading,
    &-SubHeading {
        font-size: 14px;
        line-height: 1.2;
        width: 100%;
        margin-block-end: 6px;

        @include mobile {
            font-size: 16px;
            max-width: 95%;
            margin-block-end: 2px;
            margin-block-start: 2px;
        }
    }

    &-Heading {
        color: var(--color-black);
        overflow: hidden;
        line-height: 20px;
        text-overflow: ellipsis;
        white-space: nowrap;

        @include mobile {
            line-height: 1.1;
        }
    }

    &-SubHeading {
        color: var(--cart-item-subheading-color);
    }

    &-Picture {
        width: 95px;
        height: 95px;
        align-self: flex-start;

        &_isMobileLayout {
            width: 95px;
            height: 95px;

            @include mobile {
                width: 75px;
                height: 75px;
            }

            &::after {
                content: " ";
                display: block;
                width: 100%;
                height: 100%;
                background-color: #ebeff2;
                position: absolute;
                inset-block-start: 0;
                inset-inline-start: 0;
                z-index: 10;
                opacity: .7;
                mix-blend-mode: multiply;
            }
        }
    }

    &-Price {
        font-family: $font-muli;
        font-size: 1.4rem;
        line-height: 1.2;
        font-weight: bold;
        letter-spacing: 0;
        color: $black;
        margin-block-start: auto;

        &_isMobileLayout {
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            font-weight: 700;
            display: inline-block;
            text-align: end;
            width: 60%;
            font-size: 1.4rem;
            position: absolute;
            inset-block-end: -18px;

            @include mobile {
                position: relative;
                text-align: start;
                font-size: 1.4rem;
                width: 100%;
                inset-block-end: 0;
            }

            del {
                @include desktop {
                    margin-inline-end: 10px !important;
                }
            }
        }

        &_isCartOverlay data {
            font-size: 16px;
            line-height: 20px;
        }
    }

    &-ProductActions {
        display: flex;
        align-items: center;

        &_isMobileLayout {
            justify-content: space-between;
            align-items: flex-end;

            @include mobile {
                margin-block-start: 15px;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
            }
        }
    }

    &-CartItemRows {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 0;
    }

    &-Delete {
        align-items: flex-end;
        display: flex;
        z-index: 5;
        font-family: $font-base;
        font-size: 1.2rem;
        line-height: 2.4rem;
        font-weight: normal;
        letter-spacing: 0;
        color: $default-neutral-base-color;
        margin-inline-start: 8px;

        &:hover {
            color: var(--primary-base-color);

            .TrashIcon svg {
                transform: scale(0.8);
                fill: var(--primary-base-color);
                stroke: var(--primary-base-color);
            }
        }

        svg {
            transform: scale(0.7);

            @include ultra-narrow-desktop {
                transform: scale(0.6);
            }

            @include tablet {
                transform: scale(0.6); 
            }
        }

        &_isMobileLayout {
            height: auto;
            padding-inline-start: 0;
            position: absolute;
            inset-block-end: 6px;
            inset-inline-end: 4px;

            @include desktop {
                position: absolute;
                inset-block-start: 0;
                inset-inline-end: 0;
                height: 22px;
                cursor: pointer;
            }

            @include mobile {
                inset-block-end: 50px;
            }

            svg {
                transform: scale(0.6);
                fill: #3a3939;

                @include mobile {
                    transform: scale(0.5);
                    fill: #9E9E9E;
                }

                &:hover {
                    fill: var(--primary-base-color);
                }
            }
        }
    }

    &-DeleteButtonText {
        margin-inline-start: 16px;
        cursor: pointer;
        font-size: 1.2rem;
        color: $black;

        &_isMobileLayout {
            display: none;
        }

        @include ultra-narrow-desktop {
            margin-inline-start: 8px;
        }
    }

    & &-Qty { /* hack to obtain required specificity */
        margin-block-start: 0;
        display: flex;
        cursor: default;
        border: 1px solid $default-secondary-medium-color;
        border-radius: 0px;

        @include mobile {
            align-items: center;
            font-size: 14px;
        }

        input {
            height: 40px;
            font-family: $font-muli;
            font-size: 1.6rem;
            line-height: 2.4rem;
            font-weight: 500;
            letter-spacing: 0;
            color: $black;
            border: none;

            @include mobile {
                width: 60px;
                min-width: 60px;
                background-color: transparent;
            }
        }

        button {
            width: 30px;
            height: 30px;
            font-size: 1.6rem;
            color: $default-neutral-base-color;
            border: none;

            &:not([disabled]) {
                cursor: pointer;
            }
        }
    }

    &-SwipeToDeleteRightSide {
        height: 100%;
        width: 100%;
        font-weight: 600;
        color: var(--color-white);
        background-color: var(--swipe-to-delete-bg-color);
    }

    .ProductPrice {
        @include desktop {
            min-height: auto;
        }

        &-SubPrice {
            font-size: 12px;
            font-weight: 400;
        }
    }

    .CartItem-QuantityWrapper {
        &_isCartOverlay {
            & button {
                width: 36px;
                height: 36px;
            }

            .CartItem-Qty {
                font-size: 14px;
                border: none;

                input {
                    width: fit-content;
                    min-width: unset;
                    border: none;
                    height: 14px;
                    min-height: unset;
                    font-size: 13px;
                    line-height: 1;
                    margin: 0 2px;
                    vertical-align: middle;
                    margin-block-start: -2px;
                }
            }
        }

        &_isPlaceholder {
            @include mobile {
                width: 144px;
            }
        }
    }
}
