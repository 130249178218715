/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

:root {
    --cart-overlay-totals-background: transparent;
    --cart-overlay-divider-background: #d8d8d8;
    --cart-overlay-promo-background: #fff;
}

.CartOverlay {
    --font-size: 1.4rem;

    box-shadow: 0px 3px 5px #ccc;
    cursor: auto;

    @include desktop {
        position: absolute;
        inset-block-start: calc(var(--header-nav-height) - 15px);
        inset-inline-end: -16px;
        padding: 0;
        width: 485px;
    }

    @include ultra-narrow-desktop {
        inset-block-start: calc(var(--header-nav-height) - 17px);
        width: 455px;
    }

    @include tablet {
        inset-block-start: calc(var(--header-nav-height) - 17px);
        width: 455px;
    }

    &::before {
        @include desktop {
            content: '';
            position: absolute;
            z-index: 10;
            inset-block-start: 0;
            inset-inline-end: 0;
            height: 4px;
            width: 50px;
            transform: rotate(0deg);
            background-color: $default-primary-base-color;
            border-left: none;
        }
    }

    &-ContentWrapper {
        @include desktop {
            padding: 20px 0 30px;
            font-size: 1.6rem;
        }
    }

    &-Empty {
        padding: 16px;
        text-align: center;
    }

    &-Actions {
        display: flex;
        flex-direction: column;
        padding: 30px 36px 0;

        @include mobile {
            padding: 14px;
        }

        .Button {
            max-width: 237px;
            margin: 0 auto;

            &:first-child {
                margin-block-end: 17px;
            }
        }
    }

    &-Top {
        height: 42px;
        padding-inline: 35px;
        display: flex;
        justify-content: space-between;
        align-content: center;
        flex-wrap: wrap;

        .CartOverlay-Total {
            min-height: auto;
            padding: 0;
            font-size: 1.8rem;
            font-family: $font-muli;

            dt {
                line-height: 1;
            }

            dd {
                line-height: 1;
                margin-inline-start: 10px;
            }
        }

        .CartOverlay-ItemNumber {
            padding-block-start: 4px;
            line-height: 1;
            font-weight: 400;
        }
    }

    &-OutOfStockProductsWarning {
        padding: 10px;
        display: flex;
        justify-content: center;
        background-color: var(--primary-error-color, red);
        color: var(--color-white);
        font-size: 14px;
        font-weight: 600;
    }

    &-CheckoutButton {
        margin-inline-start: 12px;

        @include mobile {
            margin-inline-start: 16px;
            width: 100%;
            display: block;
            text-align: center;
        }

        @include desktop {
            flex-grow: 1;
            text-align: center;
        }
    }

    &-CartButton {
        @include mobile {
            display: none;
        }
    }

    &-Promo,
    &-Total {
        font-weight: 600;

        @include mobile {
            min-height: 56px;
        }

        @include desktop {
            min-height: 36px;
        }
    }

    &-Promo {
        padding: 12px;
        text-align: center;
        background: var(--cart-overlay-promo-background);
        margin-block-end: 0;

        @include mobile {
            padding: 14px;
            border-block-end: 1px solid var(--cart-overlay-divider-background);
            border-block-start: 1px solid var(--expandable-content-color);
        }

        @include desktop {
            padding: 10px;
            color: var(--color-white);
            background: var(--primary-base-color);
        }

        strong {
            margin: 0 5px;
        }
    }

    &-Items {
        padding-inline: 0;
        max-height: 40vh;
        overflow-y: auto;
        
        &::-webkit-scrollbar {
            width: 6px;
            margin-inline-end: 0;
        }
        
        &::-webkit-scrollbar-track {
            background-color: #c9c9c9;
            border-radius: 3px;
        }
        
        &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background-color: #656565;
        }

        .CartItem {
            padding-inline: 35px;

            &:first-child {
                .CartItem-Wrapper_isMobileLayout {
                    padding-block-start: 6px;
                }
            }

            .CartItem-Wrapper_isMobileLayout {
                border-bottom: 1px solid #E2E2E2;
            }

            &-CartItemRows {
                justify-content: center;
            }

            &-ProductInfo_isMobileLayout {
                gap: 0;
            }

            &-Variants {
                margin-block-start: 5px;
            }

            &-ProductActions_isMobileLayout {
                justify-content: space-between;
                flex-direction: column;
                align-items: flex-start;

                p {
                    margin-block-end: 0;
                }

                span:first-of-type {
                    display: none;
                }
            }

            &-Heading, 
            &-SubHeading {
                font-size: var(--font-size);
                margin-block-end: 0px;
            }

            &-Link {
                &:hover {
                    text-decoration: none;

                    .CartItem-Qty::before {
                        color: $black;
                    }
                }
            }

            &-Qty {
                font-size: var(--font-size);

                &::before {
                    content: "Quantità:";
                }

                input {
                    font-weight: 400;
                    font-size: var(--font-size);
                }

                button {
                    display: none;
                }
            }

            .CartItem-QuantityWrapper_isCartOverlay .CartItem-Qty {
                font-size: var(--font-size);
            }

            &-Price_isCartOverlay {
                font-size: 1.4rem;

                @include desktop {
                    inset-inline-end: 0;
                }
                
                data {
                    font-size: 1.4rem;
                }
            }

            &-Wrapper_isMobileLayout {
                grid-template-columns: 95px auto;
                grid-gap: 14px;
                padding: 20px 0;
                min-height: auto;
            }

            &-Brand {
                font-size: 12px;

                &_isMobileLayout {
                    line-height: 1.2;
                    margin-block-end: 0px;
                }
            }

            &-ProductInfo {
                .Variant {
                    display: inline-block;
                    margin-inline-end: 6px;

                    &.Color {
                        margin-block-end: 2px;

                        &::before {
                            content: '-';
                            margin-inline-end: 6px;
                        }
                    }
                }
            }
        }
    }

    &-Tax {
        font-size: 14px;
    }

    &-Total,
    &-Tax,
    &-Discount {
        word-wrap: break-word;
        display: flex;
        justify-content: space-between;
        padding-block-start: 12px;
        padding-block-end: 6px;
        padding-inline: 16px;
        background: var(--cart-overlay-totals-background);

        @include mobile {
            padding-block-start: 14px;
            padding-block-end: 7px;
            padding-inline: 14px;
        }

        dd {
            text-align: end;
            display: flex;
            flex-direction: column;
            justify-content: center;
            white-space: nowrap;

            span {
                display: block;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }

    &-DiscountCoupon {
        word-break: break-word;
    }

    &-Total {
        font-weight: bold;
        font-size: 18px;
        padding-inline-start: 0;
        padding-inline-end: 0;        
    }
}
